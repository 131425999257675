.cent {
  text-align: center;
  display: flex;
  justify-content: center;
}
.highlight {
  color: red;
}
.custom-form {
  width: 100%;
  display: flex;
  justify-content: center;
}
.top{
 margin:0px 4px;
}
/* .modal .modal-header .close span {
  display: block !important;
} */