.toast-container-custom{
    z-index: 9999999 !important;
    position: absolute !important;
    margin-top: 20vh !important;
    right: 10px !important;
    width: 300px !important;
    transition: all 0.5s ease-in-out;
  }
  .toaster-body{
    width: 200px!important;
  }
  .toaster-close-button:hover{
    cursor: pointer;
  }
  
  .btn-close{
    display: none !important;
  }